import React from 'react';

import { ScPage } from '../components/page/styled';
import { MainLayout } from '../layouts/main-layout';
import { AboutUsInfoSection } from '../sections/AboutUs/AboutUsInfoSection';
import { AboutUsReadyToStartSaving } from '../sections/AboutUs/ReadyToStartSaving';
import { BCorpHero } from '../sections/BCorp/Hero';
import {SEO} from "../components/Seo/Seo";

export const BCorp = () => {
	return (
		<MainLayout>
			<ScPage>
				<BCorpHero />
				<AboutUsInfoSection />
				<AboutUsReadyToStartSaving />
			</ScPage>
		</MainLayout>
	);
};

export default BCorp;

export const Head = () => (
	<SEO
		title="Gogeta is Proud to be a BCorp | Fair, Transparent, Impactful"
		description="Gogeta, the only BCorp salary sacrifice provider, offers fair benefits for partners, employees, and a positive social impact."
	/>
)
